import { API_ROUTES } from "../../../config/routes";
import { Authenticate as AuthenticateService } from "../../../services/Authenticate";
import Request from "../../../services/Request";
import { Constants } from "../constants";
import { AppActions, IGroup, IUser } from "../types";
import AppReducer from "./app";

export default {
  login(user: IUser): AppActions {
    return {
      payload: { ...user },
      type: Constants.LOG_IN,
    };
  },
  logout(user?: IUser): any {
    AuthenticateService.logout();
    return {
      payload: { ...user },
      type: Constants.LOG_OUT,
    };
  },
  getUserGroups(): any {
    return (dispatch: any) => {
      dispatch(AppReducer.launchLoading());
      Request.get(API_ROUTES.USER_GROUP)
        .then((res: any) => {
          dispatch({
            payload: {
              groups: res.data,
            },
            type: Constants.RETRIEVE_USER_GROUPS,
          });
          dispatch(AppReducer.stopLoading());
        });
    };
  },
};
