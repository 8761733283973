// API Routes
export const USER_API_BASE_ROUTE = "/user/";
export const USER_GROUP_API_BASE_ROUTE = "/user/userGroups";
export const GROUP_API_BASE_ROUTE = "/group/";
export const REQUEST_API_BASE_ROUTE = "/request/";

// Local Routes

export const DASHBOARD_LOCAL_PAGE_ROUTE = "/dashboard";
export const PROFILE_LOCAL_PAGE_ROUTE = "/";
export const SIGN_UP_LOCAL_PAGE_ROUTE = "/signup";
export const LOGOUT_LOCAL_PAGE_ROUTE = "/logout";
export const THANKS_LOCAL_PAGE_ROUTE = "/thanks";
export const REQUESTS_LOCAL_PAGE_ROUTE = "/requests";
export const USERS_LOCAL_PAGE_ROUTE = "/users";
export const GROUPS_LOCAL_PAGE_ROUTE = "/groups";

export const API_ROUTES = {
  USER: USER_API_BASE_ROUTE,
  GROUP: GROUP_API_BASE_ROUTE,
  REQUEST: REQUEST_API_BASE_ROUTE,
  USER_GROUP: USER_GROUP_API_BASE_ROUTE,
};

export const LOCAL_ROUTES = {
  DASHBOARD: DASHBOARD_LOCAL_PAGE_ROUTE,
  PROFILE: PROFILE_LOCAL_PAGE_ROUTE,
  SIGN_UP: SIGN_UP_LOCAL_PAGE_ROUTE,
  LOGOUT: LOGOUT_LOCAL_PAGE_ROUTE,
  THANKS: THANKS_LOCAL_PAGE_ROUTE,
  REQUESTS: REQUESTS_LOCAL_PAGE_ROUTE,
  USERS: USERS_LOCAL_PAGE_ROUTE,
  GROUPS: GROUPS_LOCAL_PAGE_ROUTE,
};
