import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UserActions } from "../../store/redux/actions";

interface IProfilProps {
    user: any;
    getUserGroups: any;
}

function Profil(props: IProfilProps) {
    const { t } = useTranslation();

    useEffect(() => {
        props.getUserGroups();
    }, []);

    return (
        <>
            <div className="accmgt_table row m-auto justify-content-center align-items-center">
                <div className="col bg-white">
                    <p>{t("admin:user.firstName")}: {props.user.firstName}</p>
                    <p>{t("admin:user.lastName")}: {props.user.lastName}</p>
                    <p>{t("admin:user.email")}: {props.user.email}</p>
                    <p>{t("admin:user.login")}: {props.user.username}</p>
                    <p>{t("admin:user.groups")}:</p>
                    {props.user.groups && (
                        <ul>
                            {props.user.groups.map((item: any, index: number) => {
                                return (
                                    <li key={index}>{item.dn}</li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(UserActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profil);
