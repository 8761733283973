import { mdiAccountMultiple, mdiBookMultiple, mdiFileFind, mdiHome } from "@mdi/js";
import Icon from "@mdi/react";
import classnames from "classnames";
import React, { ReactNode, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { SzSideMenu } from "react-theme-components";
import { LOCAL_ROUTES } from "../../config/routes";
import { IError } from "../../store/redux/types";
import Header from "../Header/Header";
import Loading from "../Utils/loading";
import ErrorMessage from "../Utils/messages/errorMessage";
import "./container.scss";

interface IContainerProps {
  connected: boolean;
  children: ReactNode;
  error: IError;
  loading: boolean;
  isAdmin: boolean;
}

function Container(Props: IContainerProps) {
  const [isOpen, setIsOpen] = useState(true);
  const { children, connected, error, loading, isAdmin } = Props;
  const asideClasses = classnames("gaia-aside float-left", {
    connected,
  });
  const containerClasses = classnames("gaia-container", {
    connected,
    "float-right": connected,
    "isOpen": connected && isOpen,
    "no-connected": !connected,
    "no-menu": !isAdmin,
  });
  const menu = [
    // {
    //   icon: "mdi mdi-home",
    //   iconPath: mdiHome,
    //   link: LOCAL_ROUTES.DASHBOARD,
    //   title: "Tableau de bord",
    // },
    {
      icon: "mdi mdi-file-find",
      iconPath: mdiFileFind,
      link: LOCAL_ROUTES.REQUESTS,
      title: "Demandes",
    },
    {
      icon: "mdi mdi-account-multiple",
      iconPath: mdiAccountMultiple,
      link: LOCAL_ROUTES.USERS,
      title: "Utilisateurs",
    },
    {
      icon: "mdi mdi-book-multiple",
      iconPath: mdiBookMultiple,
      link: LOCAL_ROUTES.GROUPS,
      title: "Groupes",
    },
  ];
  if (connected) {
  return (
      <>
        {error.message && <ErrorMessage />}
      {loading && <Loading />}
        <div className="gaia-header-container">
          <Header handleOnClick={() => setIsOpen(!isOpen)} />
        </div>
      {connected && isAdmin && (
        <>
        <SzSideMenu className={asideClasses} isOpen={isOpen}>
          <ul className="sz-aside__list list-unstyled m-0">
            {menu.map((item, key) => (
              <OverlayTrigger
                key={key}
                placement={"right"}
                overlay={
                  <Tooltip id={`tooltip-right`}>
                    {item.title}
                  </Tooltip>
                }
              >
              <li
                key={key}
                className="d-flex align-items-center"
              >
                <NavLink
                  activeClassName="active"
                  className="sz-aside__item d-flex align-items-center
                  font-weight-bold text-uppercase text-decoration-none py-2"
                  exact={true}
                  to={item.link}
                >
                  <div className={"gaia-aside-icon-menu"}>
                    <Icon className={"icon-menu"} path={item.iconPath} size={0.9}/>
                  </div>
                  <span className={"sz-aside__item--title"}>{item.title}</span>
                </NavLink>
              </li>
              </OverlayTrigger>
            ))}
          </ul>
        </SzSideMenu>
        </>
      )}
        <div className={containerClasses}>{children}</div>
      </>
    );
  }
  return (
    <>
      <div className="gaia-header-container">
        <Header  handleOnClick={() => setIsOpen(!isOpen)} />
      </div>
      {loading && <Loading />}
      <div className={containerClasses}>{children}</div>
    </>
  );

}

const mapStateToProps =
  (state: {user: {logged: boolean, isAdmin: boolean}, error: IError, app: {loading: boolean}}) => {
  return {
    connected: state.user.logged,
    isAdmin: state.user.isAdmin,
    error: state.error,
    loading: state.app.loading,
  };
};

export default connect(mapStateToProps)(Container);
