// action strings
export enum Constants {
    LOG_IN = "login",
    LOG_OUT = "logout",
    TOKEN_VALID = "token_valid",
    SIGNUP = "signup",
    RETRIEVE_USERS = "RETRIEVE_USERS",
    RETRIEVE_USER_GROUPS = "RETRIEVE_USER_GROUPS",
    RETRIEVE_REQUESTS = "RETRIEVE_REQUESTS",
    RETRIEVE_GROUPS = "RETRIEVE_GROUPS",
    RESET_USERS_FILTER = "RESET_USERS_FILTER",
}

export const ERROR  = {
    SET : "set_error",
    RESET: "reset_error",
    FORM: {
        SET: "set_errors_form",
    },
};

export const APP = {
    LOADING : {START : "start_loading", STOP: "stop_loading"},
};

export enum HttpCodes {
    HTTP_OK = 200,
    HTTP_NO_CONTENT = 204,
    HTTP_NOT_FOUND = 404,
}
